export const fetchMethods = {
	GET: 'GET',
	POST: 'POST',
	PUT: 'PUT',
	PATCH: 'PATCH',
	DELETE: 'DELETE',
};

export const testingSteps = ['Enter Testing details', 'Enter Session details', 'Start Testing'];

export const roles = {
	SUPERUSER: 'hac.admin',
	MANUFACTURING_ADMIN: 'hac.manufacturing.admin',
	MANUFACTURING_TESTER: 'hac.manufacturing.tester',
	MANUFACTURING_COORDINATOR: 'hac.manufacturing.coordinator',
	SOLABORATE_LOGISTICS: 'manufacturing.solaborate.logistics',
	FACTORY_LOGISTICS: 'manufacturing.factory.logistics',
};

export const testingType = {
	moduleTester: 'MT',
	moduleMatcher: 'MM',
	fullDeviceTester: 'FD',
	packagingTester: 'PT',
	audioModule: 'AM',
};

export const reactSelectStyles = {
	singleValue: (baseStyles) => ({
		...baseStyles,
		color: '#777',
	}),
	control: (baseStyles) => ({
		...baseStyles,
		backgroundColor: 'transparent',
	}),
	menu: (provided) => ({
		...provided,
		zIndex: 123123,
	}),
	option: (styles, { isFocused }) => {
		styles[':active'].backgroundColor = '#fff';
		return {
			...styles,
			backgroundColor: isFocused ? '#999999' : null,
			color: '#333333',
		};
	},
	input: (baseStyles) => ({
		...baseStyles,
		color: '#777',
	}),
};

/// using this data until backend is configured

export const moduleTestingValidations = {
	'hello 3': {
		'mic board': {
			conventions: ['3MR', '3ML'],
			lengths: [13],
		},
		'main board': {
			conventions: ['H3B'],
			lengths: [13],
		},
		'led board': {
			conventions: ['H3L', 'H2L', 'H2+L'],
			lengths: [13, 14],
		},
		'hello pillow board': {
			conventions: ['H3P'],
			lengths: [13],
		},
	},
	'expand vision 5': {
		'main board': {
			conventions: ['BBO'],
			lengths: [13],
		},
		sbab: {
			conventions: ['BSB'],
			lengths: [13],
		},
		'mic board': {
			conventions: ['BMI'],
			lengths: [13],
		},
		'led board': {
			conventions: ['BLE'],
			lengths: [13],
		},
	},
	'expand control uc': {
		'main board': {
			conventions: ['GBO'],
			lengths: [13],
		},
		'led board': {
			conventions: ['GLL', 'GLR'],
			lengths: [13],
		},
	},
};

export const moduleMatchingConfigs = {
	ecuc: [
		{
			deviceHwModuleId: 1,
			deviceHwModuleName: 'Main Board',
			inputName: 'mainBoard',
			placeholder: 'Main Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 2,
			deviceHwModuleName: 'Led Left Board',
			inputName: 'ledLeftBoard',
			placeholder: 'Prefix SN: GLL',
			modulePart: 'L',
		},
		{
			deviceHwModuleId: 2,
			deviceHwModuleName: 'Led Right Board',
			inputName: 'ledRightBoard',
			placeholder: 'Prefix SN: GLR',
			modulePart: 'R',
		},
		{
			deviceHwModuleId: 8,
			deviceHwModuleName: 'Proximity Board',
			inputName: 'proximityBoard',
			placeholder: 'Proximity Board SN',
			modulePart: '',
		},
	],
	h2: [
		{
			deviceHwModuleId: 1,
			deviceHwModuleName: 'Main Board',
			inputName: 'mainBoard',
			placeholder: 'Main Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 3,
			deviceHwModuleName: 'Daughter Board',
			inputName: 'daughterBoard',
			placeholder: 'Daughter Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 2,
			deviceHwModuleName: 'Led Board',
			inputName: 'ledBoard',
			placeholder: 'Led Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 6,
			deviceHwModuleName: 'Camera SN',
			inputName: 'camera',
			placeholder: 'Camera SN',
			modulePart: '',
		},
	],
	'h2+': [
		{
			deviceHwModuleId: 1,
			deviceHwModuleName: 'Main Board',
			inputName: 'mainBoard',
			placeholder: 'Main Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 2,
			deviceHwModuleName: 'Led Board',
			inputName: 'ledBoard',
			placeholder: 'Led Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 6,
			deviceHwModuleName: 'Camera SN',
			inputName: 'camera',
			placeholder: 'Camera SN',
			modulePart: '',
		},
	],
	ev3: [
		{
			deviceHwModuleId: 1,
			deviceHwModuleName: 'Main Board',
			inputName: 'mainBoard',
			placeholder: 'Main Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 3,
			deviceHwModuleName: 'Daughter Board',
			inputName: 'daughterBoard',
			placeholder: 'Daughter Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 2,
			deviceHwModuleName: 'Led Board',
			inputName: 'ledBoard',
			placeholder: 'Led Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 6,
			deviceHwModuleName: 'Camera SN',
			inputName: 'camera',
			placeholder: 'Camera SN',
			modulePart: '',
		},
	],
	ev5: [
		{
			deviceHwModuleId: 1,
			deviceHwModuleName: 'Main Board',
			inputName: 'mainBoard',
			placeholder: 'Main Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 7,
			deviceHwModuleName: 'SBAB',
			inputName: 'sbab',
			placeholder: 'SBAB',
			modulePart: '',
		},
		{
			deviceHwModuleId: 2,
			deviceHwModuleName: 'Led Board',
			inputName: 'ledBoard',
			placeholder: 'Led Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 6,
			deviceHwModuleName: 'Camera SN',
			inputName: 'camera',
			placeholder: 'Camera SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 4,
			deviceHwModuleName: 'Mic Board',
			inputName: 'micBoard',
			placeholder: 'Mic Board',
			modulePart: '',
		},
	],
	ev3t: [
		{
			deviceHwModuleId: 1,
			deviceHwModuleName: 'Main Board',
			inputName: 'mainBoard',
			placeholder: 'Main Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 3,
			deviceHwModuleName: 'Daughter Board',
			inputName: 'daughterBoard',
			placeholder: 'Daughter Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 2,
			deviceHwModuleName: 'Led Board',
			inputName: 'ledBoard',
			placeholder: 'Led Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 6,
			deviceHwModuleName: 'Camera SN',
			inputName: 'camera',
			placeholder: 'Camera SN',
			modulePart: '',
		},
	],
	ev5t: [
		{
			deviceHwModuleId: 1,
			deviceHwModuleName: 'Main Board',
			inputName: 'mainBoard',
			placeholder: 'Main Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 7,
			deviceHwModuleName: 'SBAB',
			inputName: 'sbab',
			placeholder: 'SBAB',
			modulePart: '',
		},
		{
			deviceHwModuleId: 2,
			deviceHwModuleName: 'Led Board',
			inputName: 'ledBoard',
			placeholder: 'Led Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 6,
			deviceHwModuleName: 'Camera SN',
			inputName: 'camera',
			placeholder: 'Camera SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 4,
			deviceHwModuleName: 'Mic Board',
			inputName: 'micBoard',
			placeholder: 'Mic Board',
			modulePart: '',
		},
	],
	h3: [
		{
			deviceHwModuleId: 1,
			deviceHwModuleName: 'Main Board',
			inputName: 'mainBoard',
			placeholder: 'Main Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 2,
			deviceHwModuleName: 'Led Board',
			inputName: 'ledBoard',
			placeholder: 'Led Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 6,
			deviceHwModuleName: 'Camera SN',
			inputName: 'camera',
			placeholder: 'Camera SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 9,
			deviceHwModuleName: 'Pillow Speaker',
			inputName: 'pillowSpeaker',
			placeholder: 'Pillow Speaker',
			modulePart: '',
		},
	],
};

export const generalConfigurationsTypes = {
	DEACTIVATED_CONFIGURATIONS: 'deActivatedConfigurations',
	CONFIGURATIONS_HISTORY: 'generalConfigurationsHistory',
	GENERAL_CONFIGURATIONS: 'generalConfigurations',
};

export const generalConfigurationsCategory = {
	TESTING_SESSION_CONFIGURATIONS: 'testing_session_configurations',
	MASTER_MODEL: 'master_model',
};

export const usersTables = {
	users: 'users',
	invites: 'invites',
	deactivated: 'deactivated',
};

export const calibrationsTables = {
	tests: 'tests',
	profiles: 'profiles',
	setups: 'setups',
};

export const factoryTestingStations = {
	MODULE_TESTER: 1,
	MODULE_MATCHER: 2,
	FULL_DEVICE_TESTER: 3,
	PACKAGING: 4,
	AUDIO_MODULE: 5,
};

export const factoryTestingStatus = {
	TEST_IN_PROGRESS: 1,
	TEST_COMPLETED: 2,
	TEST_CANCELED_MANUALLY: 3,
	TEST_FAILED_UPLOADING: 4,
	TEST_SESSION_EXPIRED: 5,
	TEST_FAILED: 6,
	DEVICE_REPACKAGED: 7,
	PRODUCT_RETESTED: 8,
	CERTIFICATE_INSTALLATION_FAILED: 9,
	PENDING_APPROVAL: 10,
	WAIVED: 11,
};

export const pageTitles = {
	DASHBOARD: 'Dashboard',
	USERS: 'Users',
	AUDIO_ANALYSIS: 'Audio Analysis',
	TESTED_DEVICES: 'Tested Devices',
	DEVICE_DETAILS: 'Device Details',
	DEVICE_TESTING_HISTORY: 'Device Testing History',
	MIC_CALIBRATIONS: 'Mic Calibrations',
	TESTING_APPS: 'Testing Apps',
	TESTING_APPS_DETAILS: 'Testing Apps Details',
	TFS_RELEASES: 'Tfs Releases',
	WATS_REPORTS: 'WATS Reports',
	MODULE_TESTER: 'Factory Module Tester',
	MODULE_MATCHER: 'Factory Module Matcher',
	FULL_DEVICE_TESTER: 'Factory Full Device Tester',
	FACTORY_PACKAGING: 'Factory Packaging',
	SHIPPING_REQUESTS: 'Shipping Requests',
	SHIPPING_REQUESTS_DETAILS: 'Shipping Request Details',
	CREATE_SHIPPING_REQUEST: 'Create Shipping Request',
	GENERAL_CONFIGURATIONS: 'General Configurations',
	APP_MODULE_CONFIGURATIONS: 'App Module Configurations',
	FACTORY_FILES: 'Factory Files',
	AUDIO_MODULE_TESTER: 'Audio Module Tester',
	MANUFACTURING_CLIENTS: 'Manufacturing Clients',
	DEVICE_STOCK_MANAGEMENT: 'Device Stock Management',
	SHIPPING_ORDER_MANAGEMENT: 'Shipping Order Management',
};

export const WATSTestSessionsID = 6;

export const ElectricalTestsTableID = 7;

export const appearanceModes = {
	DARK_MODE: 'dark',
	LIGHT_MODE: 'light',
};

export const dashboardChartTypes = {
	Barchart: 1,
	PieChart: 2,
};

export const yieldTypes = {
	YieldByStation: 2,
	YieldBySku: 3,
	YieldByStationAndSKU: 10,
};
export const deviceHwModulesID = {
	MAIN_BOARD: 1,
	LED_BOARD: 2,
	DAUGHTER_BOARD: 3,
	MIC_BOARD: 4,
	PRIVACY_BUTTON_BOARD: 5,
	CAMERA_MODULE_IQC: 6,
	SBAB: 7,
	PROXIMITY_BOARD: 8,
	HELLO_PILLOW_BOARD: 9,
	XMOS_BOARD: 11,
};

export const deviceCodes = {
	HELLO_3: 'H3',
	HELLO_PTZ_CAM: 'HPC',
	IRG: 'IRG',
	EXPAND_CONTROL_UC: 'ECUC',
	EXPAND_VISION_5: 'EV5',
	EXPAND_VISION_3: 'EV3',
	HELLO_2_PLUS: 'H2+',
	HELLO_2: 'H2',
};

export const configurationCategories = {
	SESSION_CONFIGURATION: 1,
	GENERAL_CONFIGURATION: 2,
};

export const chartTypes = {
	AUDIO: 'audio',
	MODULE: 'module',
};

export const testBoxes = {
	TB1: 'TB200001',
	TB2: 'TB200002',
	TB3: 'TB200003',
	TB4: 'TB200004',
	KS1: 'KS00001',
	BT1: 'BT300001',
};

export const factoryFileStatuses = [
	{
		id: 1,
		name: 'Open',
	},
	{
		id: 2,
		name: 'InReview',
	},
	{
		id: 3,
		name: 'Approved',
	},
	{
		id: 4,
		name: 'Rejected',
	},
];

export const deviceFamilyIds = {
	HELLOCARE_TV_55: 24,
	HELLOCARE_TV_43: 23,
	HELLOCARE_TV_32: 22,
	HELLOCARE_TV_27: 21,
	HELLOCARE_TABLET_15: 20,
	HELLOCARE_TABLET_13: 19,
	HELLOCARE_TABLET_10: 17,
	HELLO_PTZ: 15,
	HELLO_3: 13,
	EXPAND_CONTROL_UC: 9,
	EXPAND_VISION_5: 6,
	HELLO_2_PLUS: 4,
	HELLO_2: 3,
	HELLO_1: 1,
};

export const testingStepsIds = {
	testingForm: 1,
	testingSessionForm: 2,
	testingSession: 3,
};

export const FactoryFileTypes = {
	PcbaResults: 1,
	ShippingDevices: 2,
	AdapterVoltages: 3,
	AdapterEdac: 4,
};

export const StockDeviceStatus = [
	{
		label: 'Available',
		value: 0,
		color: 'green',
	},
	{
		label: 'Reserved',
		value: 1,
		color: 'orange',
	},
	{
		label: 'ReadyForPickup',
		value: 2,
		color: 'orange',
	},
	{
		label: 'InRepair',
		value: 3,
		color: 'red',
	},
	{
		label: 'Defective',
		value: 4,
		color: 'red',
	},
	{
		label: 'Shipped',
		value: 5,
		color: 'blue',
	},
];

export const StockEntryMethod = [
	{
		label: 'Automatic',
		value: 0,
	},
	{
		label: 'Manual',
		value: 1,
	},
];

export const ShippingOrderStatus = [
	{
		label: 'Open',
		value: 0,
		color: 'yellow',
		allowedRoles: [roles.SUPERUSER, roles.MANUFACTURING_ADMIN, roles.SOLABORATE_LOGISTICS, roles.FACTORY_LOGISTICS],
	},
	{
		label: 'InvoicePending',
		value: 1,
		color: 'orange',
		allowedRoles: [roles.SUPERUSER, roles.MANUFACTURING_ADMIN, roles.SOLABORATE_LOGISTICS, roles.FACTORY_LOGISTICS],
	},
	{
		label: 'Approved',
		value: 2,
		color: 'green',
		allowedRoles: [roles.SUPERUSER, roles.MANUFACTURING_ADMIN, roles.SOLABORATE_LOGISTICS, roles.FACTORY_LOGISTICS],
	},
	{
		label: 'ShippingDetails',
		value: 3,
		color: 'blue',
		allowedRoles: [roles.SUPERUSER, roles.MANUFACTURING_ADMIN, roles.SOLABORATE_LOGISTICS],
	},
	{
		label: 'Completed',
		value: 4,
		color: 'green',
		allowedRoles: [roles.SUPERUSER, roles.MANUFACTURING_ADMIN, roles.SOLABORATE_LOGISTICS],
	},
	{
		label: 'Rejected',
		value: 5,
		color: 'red',
		allowedRoles: [roles.SUPERUSER, roles.MANUFACTURING_ADMIN, roles.SOLABORATE_LOGISTICS],
	},
	{
		label: 'Error',
		value: 6,
		color: 'red',
		allowedRoles: [roles.SUPERUSER, roles.MANUFACTURING_ADMIN, roles.SOLABORATE_LOGISTICS],
	},
];

export const InvoiceTypes = [
	{
		name: 'Comercial',
		value: 0,
	},
	{
		name: 'PackagingList',
		value: 1,
	},
];

export const AllCountries = [
	{ label: 'Afghanistan', value: 'Afghanistan', prefix: '+93' },
	{ label: 'Albania', value: 'Albania', prefix: '+355' },
	{ label: 'Algeria', value: 'Algeria', prefix: '+213' },
	{ label: 'Andorra', value: 'Andorra', prefix: '+376' },
	{ label: 'Angola', value: 'Angola', prefix: '+244' },
	{ label: 'Antigua and Barbuda', value: 'Antigua and Barbuda', prefix: '+1-268' },
	{ label: 'Argentina', value: 'Argentina', prefix: '+54' },
	{ label: 'Armenia', value: 'Armenia', prefix: '+374' },
	{ label: 'Australia', value: 'Australia', prefix: '+61' },
	{ label: 'Austria', value: 'Austria', prefix: '+43' },
	{ label: 'Azerbaijan', value: 'Azerbaijan', prefix: '+994' },
	{ label: 'Bahamas', value: 'Bahamas', prefix: '+1-242' },
	{ label: 'Bahrain', value: 'Bahrain', prefix: '+973' },
	{ label: 'Bangladesh', value: 'Bangladesh', prefix: '+880' },
	{ label: 'Barbados', value: 'Barbados', prefix: '+1-246' },
	{ label: 'Belarus', value: 'Belarus', prefix: '+375' },
	{ label: 'Belgium', value: 'Belgium', prefix: '+32' },
	{ label: 'Belize', value: 'Belize', prefix: '+501' },
	{ label: 'Benin', value: 'Benin', prefix: '+229' },
	{ label: 'Bhutan', value: 'Bhutan', prefix: '+975' },
	{ label: 'Bolivia', value: 'Bolivia', prefix: '+591' },
	{ label: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina', prefix: '+387' },
	{ label: 'Botswana', value: 'Botswana', prefix: '+267' },
	{ label: 'Brazil', value: 'Brazil', prefix: '+55' },
	{ label: 'Brunei', value: 'Brunei', prefix: '+673' },
	{ label: 'Bulgaria', value: 'Bulgaria', prefix: '+359' },
	{ label: 'Burkina Faso', value: 'Burkina Faso', prefix: '+226' },
	{ label: 'Burundi', value: 'Burundi', prefix: '+257' },
	{ label: 'Cabo Verde', value: 'Cabo Verde', prefix: '+238' },
	{ label: 'Cambodia', value: 'Cambodia', prefix: '+855' },
	{ label: 'Cameroon', value: 'Cameroon', prefix: '+237' },
	{ label: 'Canada', value: 'Canada', prefix: '+1' },
	{ label: 'Central African Republic', value: 'Central African Republic', prefix: '+236' },
	{ label: 'Chad', value: 'Chad', prefix: '+235' },
	{ label: 'Chile', value: 'Chile', prefix: '+56' },
	{ label: 'China', value: 'China', prefix: '+86' },
	{ label: 'Colombia', value: 'Colombia', prefix: '+57' },
	{ label: 'Comoros', value: 'Comoros', prefix: '+269' },
	{ label: 'Congo, Democratic Republic of the', value: 'Congo, Democratic Republic of the', prefix: '+243' },
	{ label: 'Congo, Republic of the', value: 'Congo, Republic of the', prefix: '+242' },
	{ label: 'Costa Rica', value: 'Costa Rica', prefix: '+506' },
	{ label: 'Croatia', value: 'Croatia', prefix: '+385' },
	{ label: 'Cuba', value: 'Cuba', prefix: '+53' },
	{ label: 'Cyprus', value: 'Cyprus', prefix: '+357' },
	{ label: 'Czech Republic', value: 'Czech Republic', prefix: '+420' },
	{ label: 'Denmark', value: 'Denmark', prefix: '+45' },
	{ label: 'Djibouti', value: 'Djibouti', prefix: '+253' },
	{ label: 'Dominica', value: 'Dominica', prefix: '+1-767' },
	{ label: 'Dominican Republic', value: 'Dominican Republic', prefix: '+1-809' },
	{ label: 'Ecuador', value: 'Ecuador', prefix: '+593' },
	{ label: 'Egypt', value: 'Egypt', prefix: '+20' },
	{ label: 'El Salvador', value: 'El Salvador', prefix: '+503' },
	{ label: 'Equatorial Guinea', value: 'Equatorial Guinea', prefix: '+240' },
	{ label: 'Eritrea', value: 'Eritrea', prefix: '+291' },
	{ label: 'Estonia', value: 'Estonia', prefix: '+372' },
	{ label: 'Eswatini', value: 'Eswatini', prefix: '+268' },
	{ label: 'Ethiopia', value: 'Ethiopia', prefix: '+251' },
	{ label: 'Fiji', value: 'Fiji', prefix: '+679' },
	{ label: 'Finland', value: 'Finland', prefix: '+358' },
	{ label: 'France', value: 'France', prefix: '+33' },
	{ label: 'Gabon', value: 'Gabon', prefix: '+241' },
	{ label: 'Gambia', value: 'Gambia', prefix: '+220' },
	{ label: 'Georgia', value: 'Georgia', prefix: '+995' },
	{ label: 'Germany', value: 'Germany', prefix: '+49' },
	{ label: 'Ghana', value: 'Ghana', prefix: '+233' },
	{ label: 'Greece', value: 'Greece', prefix: '+30' },
	{ label: 'Grenada', value: 'Grenada', prefix: '+1-473' },
	{ label: 'Guatemala', value: 'Guatemala', prefix: '+502' },
	{ label: 'Guinea', value: 'Guinea', prefix: '+224' },
	{ label: 'Guinea-Bissau', value: 'Guinea-Bissau', prefix: '+245' },
	{ label: 'Guyana', value: 'Guyana', prefix: '+592' },
	{ label: 'Haiti', value: 'Haiti', prefix: '+509' },
	{ label: 'Honduras', value: 'Honduras', prefix: '+504' },
	{ label: 'Hungary', value: 'Hungary', prefix: '+36' },
	{ label: 'Iceland', value: 'Iceland', prefix: '+354' },
	{ label: 'India', value: 'India', prefix: '+91' },
	{ label: 'Indonesia', value: 'Indonesia', prefix: '+62' },
	{ label: 'Iran', value: 'Iran', prefix: '+98' },
	{ label: 'Iraq', value: 'Iraq', prefix: '+964' },
	{ label: 'Ireland', value: 'Ireland', prefix: '+353' },
	{ label: 'Israel', value: 'Israel', prefix: '+972' },
	{ label: 'Italy', value: 'Italy', prefix: '+39' },
	{ label: 'Jamaica', value: 'Jamaica', prefix: '+1-876' },
	{ label: 'Japan', value: 'Japan', prefix: '+81' },
	{ label: 'Jordan', value: 'Jordan', prefix: '+962' },
	{ label: 'Kazakhstan', value: 'Kazakhstan', prefix: '+7' },
	{ label: 'Kenya', value: 'Kenya', prefix: '+254' },
	{ label: 'Kiribati', value: 'Kiribati', prefix: '+686' },
	{ label: 'Korea, South', value: 'Korea, South', prefix: '+82' },
	{ label: 'Kosovo', value: 'Kosovo', prefix: '+383' },
	{ label: 'Kuwait', value: 'Kuwait', prefix: '+965' },
	{ label: 'Kyrgyzstan', value: 'Kyrgyzstan', prefix: '+996' },
	{ label: 'Laos', value: 'Laos', prefix: '+856' },
	{ label: 'Latvia', value: 'Latvia', prefix: '+371' },
	{ label: 'Lebanon', value: 'Lebanon', prefix: '+961' },
	{ label: 'Lesotho', value: 'Lesotho', prefix: '+266' },
	{ label: 'Liberia', value: 'Liberia', prefix: '+231' },
	{ label: 'Libya', value: 'Libya', prefix: '+218' },
	{ label: 'Liechtenstein', value: 'Liechtenstein', prefix: '+423' },
	{ label: 'Lithuania', value: 'Lithuania', prefix: '+370' },
	{ label: 'Luxembourg', value: 'Luxembourg', prefix: '+352' },
	{ label: 'Madagascar', value: 'Madagascar', prefix: '+261' },
	{ label: 'Malawi', value: 'Malawi', prefix: '+265' },
	{ label: 'Malaysia', value: 'Malaysia', prefix: '+60' },
	{ label: 'Maldives', value: 'Maldives', prefix: '+960' },
	{ label: 'Mali', value: 'Mali', prefix: '+223' },
	{ label: 'Malta', value: 'Malta', prefix: '+356' },
	{ label: 'Marshall Islands', value: 'Marshall Islands', prefix: '+692' },
	{ label: 'Mauritania', value: 'Mauritania', prefix: '+222' },
	{ label: 'Mauritius', value: 'Mauritius', prefix: '+230' },
	{ label: 'Mexico', value: 'Mexico', prefix: '+52' },
	{ label: 'Micronesia', value: 'Micronesia', prefix: '+691' },
	{ label: 'Moldova', value: 'Moldova', prefix: '+373' },
	{ label: 'Monaco', value: 'Monaco', prefix: '+377' },
	{ label: 'Mongolia', value: 'Mongolia', prefix: '+976' },
	{ label: 'Montenegro', value: 'Montenegro', prefix: '+382' },
	{ label: 'Morocco', value: 'Morocco', prefix: '+212' },
	{ label: 'Mozambique', value: 'Mozambique', prefix: '+258' },
	{ label: 'Myanmar', value: 'Myanmar', prefix: '+95' },
	{ label: 'Namibia', value: 'Namibia', prefix: '+264' },
	{ label: 'Nauru', value: 'Nauru', prefix: '+674' },
	{ label: 'Nepal', value: 'Nepal', prefix: '+977' },
	{ label: 'Netherlands', value: 'Netherlands', prefix: '+31' },
	{ label: 'New Zealand', value: 'New Zealand', prefix: '+64' },
	{ label: 'Nicaragua', value: 'Nicaragua', prefix: '+505' },
	{ label: 'Niger', value: 'Niger', prefix: '+227' },
	{ label: 'Nigeria', value: 'Nigeria', prefix: '+234' },
	{ label: 'North Macedonia', value: 'North Macedonia', prefix: '+389' },
	{ label: 'Norway', value: 'Norway', prefix: '+47' },
	{ label: 'Oman', value: 'Oman', prefix: '+968' },
	{ label: 'Pakistan', value: 'Pakistan', prefix: '+92' },
	{ label: 'Palau', value: 'Palau', prefix: '+680' },
	{ label: 'Palestine', value: 'Palestine', prefix: '+970' },
	{ label: 'Panama', value: 'Panama', prefix: '+507' },
	{ label: 'Papua New Guinea', value: 'Papua New Guinea', prefix: '+675' },
	{ label: 'Paraguay', value: 'Paraguay', prefix: '+595' },
	{ label: 'Peru', value: 'Peru', prefix: '+51' },
	{ label: 'Philippines', value: 'Philippines', prefix: '+63' },
	{ label: 'Poland', value: 'Poland', prefix: '+48' },
	{ label: 'Portugal', value: 'Portugal', prefix: '+351' },
	{ label: 'Qatar', value: 'Qatar', prefix: '+974' },
	{ label: 'Romania', value: 'Romania', prefix: '+40' },
	{ label: 'Russia', value: 'Russia', prefix: '+7' },
	{ label: 'Rwanda', value: 'Rwanda', prefix: '+250' },
	{ label: 'Saint Kitts and Nevis', value: 'Saint Kitts and Nevis', prefix: '+1-869' },
	{ label: 'Saint Lucia', value: 'Saint Lucia', prefix: '+1-758' },
	{ label: 'Saint Vincent and the Grenadines', value: 'Saint Vincent and the Grenadines', prefix: '+1-784' },
	{ label: 'Samoa', value: 'Samoa', prefix: '+685' },
	{ label: 'San Marino', value: 'San Marino', prefix: '+378' },
	{ label: 'Sao Tome and Principe', value: 'Sao Tome and Principe', prefix: '+239' },
	{ label: 'Saudi Arabia', value: 'Saudi Arabia', prefix: '+966' },
	{ label: 'Senegal', value: 'Senegal', prefix: '+221' },
	{ label: 'Serbia', value: 'Serbia', prefix: '+381' },
	{ label: 'Seychelles', value: 'Seychelles', prefix: '+248' },
	{ label: 'Sierra Leone', value: 'Sierra Leone', prefix: '+232' },
	{ label: 'Singapore', value: 'Singapore', prefix: '+65' },
	{ label: 'Slovakia', value: 'Slovakia', prefix: '+421' },
	{ label: 'Slovenia', value: 'Slovenia', prefix: '+386' },
	{ label: 'Solomon Islands', value: 'Solomon Islands', prefix: '+677' },
	{ label: 'Somalia', value: 'Somalia', prefix: '+252' },
	{ label: 'South Africa', value: 'South Africa', prefix: '+27' },
	{ label: 'South Sudan', value: 'South Sudan', prefix: '+211' },
	{ label: 'Spain', value: 'Spain', prefix: '+34' },
	{ label: 'Sri Lanka', value: 'Sri Lanka', prefix: '+94' },
	{ label: 'Sudan', value: 'Sudan', prefix: '+249' },
	{ label: 'Suriname', value: 'Suriname', prefix: '+597' },
	{ label: 'Sweden', value: 'Sweden', prefix: '+46' },
	{ label: 'Switzerland', value: 'Switzerland', prefix: '+41' },
	{ label: 'Syria', value: 'Syria', prefix: '+963' },
	{ label: 'Taiwan', value: 'Taiwan', prefix: '+886' },
	{ label: 'Tajikistan', value: 'Tajikistan', prefix: '+992' },
	{ label: 'Tanzania', value: 'Tanzania', prefix: '+255' },
	{ label: 'Thailand', value: 'Thailand', prefix: '+66' },
	{ label: 'Timor-Leste', value: 'Timor-Leste', prefix: '+670' },
	{ label: 'Togo', value: 'Togo', prefix: '+228' },
	{ label: 'Tonga', value: 'Tonga', prefix: '+676' },
	{ label: 'Trinidad and Tobago', value: 'Trinidad and Tobago', prefix: '+1-868' },
	{ label: 'Tunisia', value: 'Tunisia', prefix: '+216' },
	{ label: 'Turkey', value: 'Turkey', prefix: '+90' },
	{ label: 'Turkmenistan', value: 'Turkmenistan', prefix: '+993' },
	{ label: 'Tuvalu', value: 'Tuvalu', prefix: '+688' },
	{ label: 'Uganda', value: 'Uganda', prefix: '+256' },
	{ label: 'Ukraine', value: 'Ukraine', prefix: '+380' },
	{ label: 'United Arab Emirates', value: 'United Arab Emirates', prefix: '+971' },
	{ label: 'United Kingdom', value: 'United Kingdom', prefix: '+44' },
	{ label: 'United States', value: 'United States', prefix: '+1' },
	{ label: 'Uruguay', value: 'Uruguay', prefix: '+598' },
	{ label: 'Uzbekistan', value: 'Uzbekistan', prefix: '+998' },
	{ label: 'Vanuatu', value: 'Vanuatu', prefix: '+678' },
	{ label: 'Vatican City', value: 'Vatican City', prefix: '+39' },
	{ label: 'Venezuela', value: 'Venezuela', prefix: '+58' },
	{ label: 'Vietnam', value: 'Vietnam', prefix: '+84' },
	{ label: 'Yemen', value: 'Yemen', prefix: '+967' },
	{ label: 'Zambia', value: 'Zambia', prefix: '+260' },
	{ label: 'Zimbabwe', value: 'Zimbabwe', prefix: '+263' },
];

export const DeliverStatus = [
	{
		label: 'Package Picked Up',
		value: 0,
		color: 'yellow',
	},
	{
		label: 'In Transit',
		value: 1,
		color: 'orange',
	},
	{
		label: 'Clearance Event',
		value: 2,
		color: 'orange',
	},
	{
		label: 'Out for deliver',
		value: 3,
		color: 'blue',
	},
	{
		label: 'Delivered',
		value: 4,
		color: 'green',
	},
];

export const InvoiceStatus = [
	{
		label: 'Pending Approval',
		value: 0,
		color: 'yellow',
	},
	{
		label: 'Approved With Requested Invoice',
		value: 1,
		color: 'green',
	},
	{
		label: 'Approved With Updated Invoice',
		value: 2,
		color: 'green',
	},
	{
		label: 'Rejected',
		value: 3,
		color: 'red',
	},
];

export const DeviceColor = [
	{
		label: 'White',
		value: 0,
	},
	{
		label: 'Black',
		value: 1,
	},
	{
		label: 'Default',
		value: 2,
	},
];
export const ChartPreferencesTypes = {
	AUDIO: 0,
	MODULE: 1,
	GROUPED: 2,
	FILE: 3,
};
