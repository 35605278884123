import { roles } from 'enums';
import { useAuth } from 'oidc-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Signin = () => {
	const { userManager } = useAuth();
	const navigate = useNavigate();

	const getNavigateUrl = (user) => {
		const navigateUrl = [roles.FACTORY_LOGISTICS, roles.SOLABORATE_LOGISTICS].some((item) => user?.profile?.role.includes(item))
			? '/device-stock-management'
			: '/devices';
		return navigateUrl;
	};

	useEffect(() => {
		const handleUserLoaded = (user) => {
			try {
				const navigateUrl = getNavigateUrl(user);
				const redirectUrl = sessionStorage.getItem('redirectUrl');
				navigate(redirectUrl || navigateUrl);
				sessionStorage.removeItem('redirectUrl');
				userManager.events.removeUserLoaded(handleUserLoaded);
			} catch (error) {
				console.error(error);
			}
		};
		userManager.events.addUserLoaded(handleUserLoaded);
		return () => {
			userManager.events.removeUserLoaded(handleUserLoaded);
		};
	}, [userManager, navigate]);

	return <></>;
};

export default Signin;
