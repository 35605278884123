import { useAuth } from 'oidc-react';
import { lazy, useEffect, useState } from 'react';
import { Spinner } from '@chakra-ui/react';
import { setUserAuthorizationData } from '../globals';
import Unauthorized from 'pages/unauthorized';
import { useLocation } from 'react-router-dom';

const Layout = lazy(() => import('layout'));

const RequireAuth = ({ roles }) => {
	const [user, setUser] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const { userManager, userData } = useAuth();
	const location = useLocation();

	useEffect(() => {
		const setUserData = (data) => {
			setUser(data);
			setUserAuthorizationData(data);
			setIsLoading(false);
		};

		const getUser = async () => {
			if (userData) {
				setUserData(userData);
				return;
			}

			const userManagerData = await userManager.getUser();

			setUserData(userManagerData);
		};
		getUser();
	}, []);

	if (isLoading) {
		return <Spinner />;
	}

	if (user && roles.some((item) => user.profile.role?.includes(item))) {
		return <Layout />;
	}

	if (user && !roles.some((item) => user.profile.role?.includes(item))) {
		return <Unauthorized />;
	}

	sessionStorage.setItem('redirectUrl', location.pathname + location.search);
	userManager.signinRedirect();

	return <></>;
};

export default RequireAuth;
